var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import Routes from 'routes';
import { Web3ReactProvider } from '@web3-react/core';
import ThemeCustomization from 'themes';
import { APIProvider } from 'contexts/ApiContext';
import { useDispatch, useSelector } from 'store';
import { ChangePage } from 'store/reducers/menu';
import { SetBetsId, SetCode, } from 'store/reducers/auth';
import Locales from 'ui-component/Locales';
import Snackbar from 'ui-component/extended/Snackbar';
import getLibrary from 'utils/getlibrary';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import { LedgerWalletAdapter, PhantomWalletAdapter, SlopeWalletAdapter, SolflareWalletAdapter, SolletExtensionWalletAdapter, SolletWalletAdapter, TorusWalletAdapter } from '@solana/wallet-adapter-wallets';
import { clusterApiUrl } from '@solana/web3.js';
import '@solana/wallet-adapter-react-ui/styles.css';
var App = function () {
    var dispatch = useDispatch();
    var pathname = useLocation().pathname;
    var _a = useSelector(function (state) { return state.auth; }), isLoggedIn = _a.isLoggedIn, balance = _a.balance, token = _a.token;
    var network = WalletAdapterNetwork.Mainnet;
    var endpoint = useMemo(function () { return clusterApiUrl(network); }, [network]);
    var wallets = useMemo(function () { return [
        new PhantomWalletAdapter(),
        new SlopeWalletAdapter(),
        new SolflareWalletAdapter({ network: network }),
        new TorusWalletAdapter(),
        new LedgerWalletAdapter(),
        new SolletWalletAdapter({ network: network }),
        new SolletExtensionWalletAdapter({ network: network })
    ]; }, [network]);
    useEffect(function () {
        var params = new URLSearchParams(window.location.search);
        var c = params.get('c');
        if (c) {
            dispatch(SetCode(c));
            dispatch(ChangePage('register'));
        }
        var b = params.get('b');
        if (b) {
            dispatch(SetBetsId(b));
            dispatch(ChangePage('bets'));
        }
    }, [pathname, dispatch]);
    return (_jsx(Web3ReactProvider, __assign({ getLibrary: getLibrary }, { children: _jsx(ConnectionProvider, __assign({ endpoint: endpoint }, { children: _jsx(WalletProvider, __assign({ wallets: wallets }, { children: _jsx(ThemeCustomization, { children: _jsx(Locales, { children: _jsx(APIProvider, { children: _jsxs(_Fragment, { children: [_jsx(Routes, {}), _jsx(Snackbar, {})] }) }) }) }) })) })) })));
};
export default App;
