var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { motion, useCycle } from 'framer-motion';
var AnimateButton = React.forwardRef(function (_a, ref) {
    var children = _a.children, type = _a.type, direction = _a.direction, offset = _a.offset, scale = _a.scale;
    var offset1;
    var offset2;
    switch (direction) {
        case 'up':
        case 'left':
            offset1 = offset;
            offset2 = 0;
            break;
        case 'right':
        case 'down':
        default:
            offset1 = 0;
            offset2 = offset;
            break;
    }
    var _b = useCycle(offset1, offset2), x = _b[0], cycleX = _b[1];
    var _c = useCycle(offset1, offset2), y = _c[0], cycleY = _c[1];
    switch (type) {
        case 'rotate':
            return (_jsx(motion.div, __assign({ ref: ref, animate: { rotate: 360 }, transition: {
                    repeat: Infinity,
                    repeatType: 'loop',
                    duration: 2,
                    repeatDelay: 0
                } }, { children: children })));
        case 'slide':
            if (direction === 'up' || direction === 'down') {
                return (_jsx(motion.div, __assign({ ref: ref, animate: { y: y !== undefined ? y : '' }, onHoverEnd: function () { return cycleY(); }, onHoverStart: function () { return cycleY(); } }, { children: children })));
            }
            return (_jsx(motion.div, __assign({ ref: ref, animate: { x: x !== undefined ? x : '' }, onHoverEnd: function () { return cycleX(); }, onHoverStart: function () { return cycleX(); } }, { children: children })));
        case 'scale':
        default:
            if (typeof scale === 'number') {
                scale = {
                    hover: scale,
                    tap: scale
                };
            }
            return (_jsx(motion.div, __assign({ ref: ref, whileHover: { scale: scale === null || scale === void 0 ? void 0 : scale.hover }, whileTap: { scale: scale === null || scale === void 0 ? void 0 : scale.tap } }, { children: children })));
    }
});
AnimateButton.defaultProps = {
    type: 'scale',
    offset: 10,
    direction: 'right',
    scale: {
        hover: 1,
        tap: 0.9
    }
};
export default AnimateButton;
