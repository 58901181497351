import { persistStore } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch as useAppDispatch, useSelector as useAppSelector } from 'react-redux';
import rootReducer from './reducers';
import persistReducer from './reducers/persistReducer';
var store = configureStore({
    reducer: persistReducer(rootReducer),
    middleware: function (getDefaultMiddleware) { return getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }); }
});
var persister = persistStore(store);
var dispatch = store.dispatch;
var useDispatch = function () { return useAppDispatch(); };
var useSelector = useAppSelector;
export { store, persister, dispatch, useSelector, useDispatch };
