var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Logout } from 'store/reducers/auth';
import Loader from 'ui-component/Loader';
import { useDispatch, useSelector } from 'store';
import axios from 'utils/axios';
var APIContext = createContext(null);
export var APIProvider = function (_a) {
    var children = _a.children;
    var dispatch = useDispatch();
    var navigate = useNavigate();
    var state = useSelector(function (store) { return store.auth; });
    var user = state.user, currencyId = state.currencyId, balanceId = state.balanceId, code = state.code;
    var userId = user === null || user === void 0 ? void 0 : user._id;
    var resetPassword = function (email) { return console.log(email); };
    var login = function (email, password, recaptcha) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/signin', {
                        email: email,
                        password: password,
                        recaptcha: recaptcha
                    })];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
            }
        });
    }); };
    // const register = async (email: string, username: string, password: string, agent: string, recaptcha: string) => {
    //     const data = await axios.post('api/v2/users/signup', {
    //         rReferral: code,
    //         email,
    //         username,
    //         password,
    //         agent,
    //         recaptcha
    //     });
    //     return data;
    // };
    var register = function (email, username, password, agent, recaptcha, passwordDeposit, bankOwner, bankAccount, bankName, phone, Birthday, Nickname, phoneType) { return __awaiter(void 0, void 0, void 0, function () {
        var data;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/signup', {
                        rReferral: code,
                        email: email,
                        username: username,
                        password: password,
                        agent: agent,
                        recaptcha: recaptcha,
                        passwordDeposit: passwordDeposit,
                        bankOwner: bankOwner,
                        bankAccount: bankAccount,
                        phone: phone,
                        bankName: bankName,
                        Birthday: Birthday,
                        Nickname: Nickname,
                        phoneType: phoneType
                    })];
                case 1:
                    data = _a.sent();
                    return [2 /*return*/, data];
            }
        });
    }); };
    var logout = function () {
        dispatch(Logout());
        navigate('/');
    };
    var forgotPassword = function (email, recaptcha) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/forgot', {
                        email: email,
                        recaptcha: recaptcha
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var changePassword = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/c-password', data)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var checkAddress = function (publicAddress) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/a-check', { publicAddress: publicAddress })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var signInAddress = function (publicAddress, signature) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/a-signin', {
                        publicAddress: publicAddress,
                        signature: signature
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var signInSolana = function (publicAddress, signature) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/s-signin', {
                        publicAddress: publicAddress,
                        signature: signature
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var signUpAddress = function (publicAddress) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/a-signup', {
                        rReferral: code,
                        publicAddress: publicAddress
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var updateUserInfo = function (info) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/info', __assign(__assign({}, info), { userId: userId }))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var getReferral = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/users/referral', { userId: userId })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var getTransactions = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/get-transaction', {
                        userId: userId
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var getBalances = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/get-balance', { userId: userId })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var getCurrency = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/get-currency', {})];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var addCurrency = function (currency) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/add-currency', {
                        userId: userId,
                        currency: currency
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var changeCurrency = function (currency) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/use-currency', {
                        userId: userId,
                        currency: currency
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var deposit = function () { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/deposit', {
                        userId: userId,
                        currencyId: currencyId,
                        balanceId: balanceId
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var depositMetamask = function (transaction) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/m-deposit', __assign({ userId: userId, balanceId: balanceId, currencyId: currencyId }, transaction))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var depositSolana = function (transaction) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/s-deposit', __assign({ userId: userId, balanceId: balanceId, currencyId: currencyId }, transaction))];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var withdrawal = function (address, method, amount) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/withdrawal', {
                        userId: userId,
                        currencyId: currencyId,
                        balanceId: balanceId,
                        address: address,
                        method: method,
                        amount: amount
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var cancelWithdrawal = function (_id) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/payments/c-withdrawal', {
                        userId: userId,
                        _id: _id
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var betSport = function (data, type, stake) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/sports/bet', {
                        data: data,
                        type: type,
                        stake: stake,
                        userId: userId,
                        currency: currencyId
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var getMybets = function (status) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/sports/history', {
                        status: status,
                        userId: userId
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var getCasinoHistory = function (type, perPage) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/games/history', {
                        type: type,
                        perPage: perPage,
                        userId: userId
                    })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var uploadFile = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/files/', data)];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    var deleteFile = function (uri) { return __awaiter(void 0, void 0, void 0, function () {
        var res;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, axios.post('api/v2/files/delete', { uri: uri })];
                case 1:
                    res = _a.sent();
                    return [2 /*return*/, res];
            }
        });
    }); };
    if (state.isInitialized !== undefined && !state.isInitialized) {
        return _jsx(Loader, {});
    }
    return (_jsx(APIContext.Provider, __assign({ value: {
            login: login,
            register: register,
            logout: logout,
            forgotPassword: forgotPassword,
            checkAddress: checkAddress,
            signInAddress: signInAddress,
            signInSolana: signInSolana,
            signUpAddress: signUpAddress,
            changePassword: changePassword,
            resetPassword: resetPassword,
            updateUserInfo: updateUserInfo,
            getReferral: getReferral,
            getTransactions: getTransactions,
            getBalances: getBalances,
            getCurrency: getCurrency,
            addCurrency: addCurrency,
            changeCurrency: changeCurrency,
            deposit: deposit,
            depositMetamask: depositMetamask,
            depositSolana: depositSolana,
            withdrawal: withdrawal,
            cancelWithdrawal: cancelWithdrawal,
            betSport: betSport,
            getMybets: getMybets,
            getCasinoHistory: getCasinoHistory,
            uploadFile: uploadFile,
            deleteFile: deleteFile
        } }, { children: children })));
};
export default APIContext;
