import { useState, useEffect } from 'react';
export default function useLocalStorage(key, defaultValue) {
    var _a = useState(function () {
        var storedValue = localStorage.getItem(key);
        return storedValue === null ? defaultValue : JSON.parse(storedValue);
    }), value = _a[0], setValue = _a[1];
    useEffect(function () {
        var listener = function (e) {
            if (e.storageArea === localStorage && e.key === key) {
                setValue(e.newValue ? JSON.parse(e.newValue) : e.newValue);
            }
        };
        window.addEventListener('storage', listener);
        return function () {
            window.removeEventListener('storage', listener);
        };
    }, [key, defaultValue]);
    var setValueInLocalStorage = function (newValue) {
        setValue(function (currentValue) {
            var result = typeof newValue === 'function' ? newValue(currentValue) : newValue;
            localStorage.setItem(key, JSON.stringify(result));
            return result;
        });
    };
    return [value, setValueInLocalStorage];
}
