var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    gameUrl: '',
    betAmount: 0,
    betslipOpen: true,
    gameCategories: [],
    _listGame: [],
    isError: false
};
var casino = createSlice({
    name: 'casino',
    initialState: initialState,
    reducers: {
        setGameUrl: function (state, action) {
            state.gameUrl = action.payload;
        },
        setGameCategories: function (state, action) {
            state.gameCategories = action.payload;
        },
        _setGameList: function (state, action) {
            state._listGame = action.payload;
        },
        _setIsError: function (state, action) {
            state.isError = action.payload;
        }
    }
});
export default casino.reducer;
export var setGameUrl = (_a = casino.actions, _a.setGameUrl), setGameCategories = _a.setGameCategories, _setGameList = _a._setGameList, _setIsError = _a._setIsError;
