var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Alert, Button, Fade, Grow, IconButton, Slide, Snackbar as MuiSnackbar } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'store';
import { closeSnackbar } from 'store/reducers/snackbar';
function TransitionSlideLeft(props) {
    return _jsx(Slide, __assign({}, props, { direction: "left" }));
}
function TransitionSlideUp(props) {
    return _jsx(Slide, __assign({}, props, { direction: "up" }));
}
function TransitionSlideRight(props) {
    return _jsx(Slide, __assign({}, props, { direction: "right" }));
}
function TransitionSlideDown(props) {
    return _jsx(Slide, __assign({}, props, { direction: "down" }));
}
function GrowTransition(props) {
    return _jsx(Grow, __assign({}, props));
}
var animation = {
    SlideLeft: TransitionSlideLeft,
    SlideUp: TransitionSlideUp,
    SlideRight: TransitionSlideRight,
    SlideDown: TransitionSlideDown,
    Grow: GrowTransition,
    Fade: Fade
};
var Snackbar = function () {
    var dispatch = useDispatch();
    var snackbar = useSelector(function (state) { return state.snackbar; });
    var actionButton = snackbar.actionButton, anchorOrigin = snackbar.anchorOrigin, alert = snackbar.alert, close = snackbar.close, message = snackbar.message, open = snackbar.open, transition = snackbar.transition, variant = snackbar.variant;
    var handleClose = function (event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeSnackbar());
    };
    return (_jsxs(_Fragment, { children: [variant === 'default' && (_jsx(MuiSnackbar, { anchorOrigin: anchorOrigin, open: open, autoHideDuration: 6000, onClose: handleClose, message: message, TransitionComponent: animation[transition], action: _jsxs(_Fragment, { children: [_jsx(Button, __assign({ color: "secondary", size: "small", onClick: handleClose }, { children: "UNDO" })), _jsx(IconButton, __assign({ size: "small", "aria-label": "close", color: "inherit", onClick: handleClose, sx: { mt: 0.25 } }, { children: _jsx(CloseIcon, { fontSize: "small" }) }))] }) })), variant === 'alert' && (_jsx(MuiSnackbar, __assign({ TransitionComponent: animation[transition], anchorOrigin: anchorOrigin, open: open, autoHideDuration: 6000, onClose: handleClose }, { children: _jsx(Alert, __assign({ variant: alert.variant, color: alert.color, action: _jsxs(_Fragment, { children: [actionButton !== false && (_jsx(Button, __assign({ size: "small", onClick: handleClose, sx: { color: 'background.paper' } }, { children: "UNDO" }))), close !== false && (_jsx(IconButton, __assign({ sx: { color: 'background.paper' }, size: "small", "aria-label": "close", onClick: handleClose }, { children: _jsx(CloseIcon, { fontSize: "small" }) })))] }), sx: __assign({}, (alert.variant === 'outlined' && {
                        bgcolor: 'background.paper'
                    })) }, { children: message })) })))] }));
};
export default Snackbar;
