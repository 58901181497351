var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { useTheme, styled, Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone';
import { HOME_PATH } from 'config';
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import imageBlue from 'assets/images/maintenance/img-error-blue.svg';
import imageText from 'assets/images/maintenance/img-error-text.svg';
import imageBackground from 'assets/images/maintenance/img-error-bg.svg';
import imagePurple from 'assets/images/maintenance/img-error-purple.svg';
import imageDarkBackground from 'assets/images/maintenance/img-error-bg-dark.svg';
var CardMediaWrapper = styled('div')({
    maxWidth: 720,
    margin: '0 auto',
    position: 'relative'
});
var ErrorWrapper = styled('div')({
    maxWidth: 350,
    margin: '0 auto',
    textAlign: 'center'
});
var ErrorCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});
var CardMediaBlock = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '3s bounce ease-in-out infinite'
});
var CardMediaBlue = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '15s wings ease-in-out infinite'
});
var CardMediaPurple = styled('img')({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    animation: '12s wings ease-in-out infinite'
});
var Error = function () {
    var theme = useTheme();
    return (_jsx(ErrorCard, { children: _jsx(CardContent, { children: _jsxs(Grid, __assign({ container: true, justifyContent: "center", spacing: gridSpacing }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(CardMediaWrapper, { children: [_jsx(CardMedia, { component: "img", image: theme.palette.mode === 'dark' ? imageDarkBackground : imageBackground, title: "Slider5 image" }), _jsx(CardMediaBlock, { src: imageText, title: "Slider 1 image" }), _jsx(CardMediaBlue, { src: imageBlue, title: "Slider 2 image" }), _jsx(CardMediaPurple, { src: imagePurple, title: "Slider 3 image" })] }) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(ErrorWrapper, { children: _jsxs(Grid, __assign({ container: true, spacing: gridSpacing }, { children: [_jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(Typography, __assign({ variant: "h1", component: "div" }, { children: "Something is wrong" })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsxs(Typography, __assign({ variant: "body2" }, { children: ["The page you are looking was moved, removed, renamed, or might never exist!", ' '] })) })), _jsx(Grid, __assign({ item: true, xs: 12 }, { children: _jsx(AnimateButton, { children: _jsxs(Button, __assign({ variant: "contained", size: "large", component: Link, to: HOME_PATH }, { children: [_jsx(HomeTwoToneIcon, { sx: { fontSize: '1.3rem', mr: 0.75 } }), " Home"] })) }) }))] })) }) }))] })) }) }));
};
export default Error;
