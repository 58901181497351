var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    action: false,
    open: false,
    message: 'Note archived',
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right'
    },
    variant: 'default',
    alert: {
        color: 'primary',
        variant: 'filled'
    },
    transition: 'Fade',
    close: true,
    actionButton: false
};
var snackbar = createSlice({
    name: 'snackbar',
    initialState: initialState,
    reducers: {
        openSnackbar: function (state, action) {
            var _a = action.payload, open = _a.open, message = _a.message, anchorOrigin = _a.anchorOrigin, variant = _a.variant, alert = _a.alert, transition = _a.transition, close = _a.close, actionButton = _a.actionButton;
            state.action = !state.action;
            state.open = open || initialState.open;
            state.message = message || initialState.message;
            state.anchorOrigin = anchorOrigin || initialState.anchorOrigin;
            state.variant = variant || initialState.variant;
            state.alert = {
                color: (alert === null || alert === void 0 ? void 0 : alert.color) || initialState.alert.color,
                variant: (alert === null || alert === void 0 ? void 0 : alert.variant) || initialState.alert.variant
            };
            state.transition = transition || initialState.transition;
            state.close = close === false ? close : initialState.close;
            state.actionButton = actionButton || initialState.actionButton;
        },
        closeSnackbar: function (state) {
            state.open = false;
        }
    }
});
export default snackbar.reducer;
export var closeSnackbar = (_a = snackbar.actions, _a.closeSnackbar), openSnackbar = _a.openSnackbar;
