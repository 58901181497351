import { store } from 'store';
import { openSnackbar } from 'store/reducers/snackbar';
var snackbar = function (message, color) {
    if (color === void 0) { color = 'success'; }
    store.dispatch(openSnackbar({
        open: true,
        message: message,
        variant: 'alert',
        transition: 'SlideLeft',
        alert: { color: color },
        anchorOrigin: { vertical: 'top', horizontal: 'right' }
    }));
};
export default snackbar;
