var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Web3ReactProvider } from '@web3-react/core';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import App from 'App';
import { BASE_PATH } from 'config';
import { store, persister } from 'store';
import getLibrary from 'utils/getlibrary';
import { ConfigProvider } from 'contexts/ConfigContext';
import 'assets/scss/style.scss';
import 'moment/locale/ja';
import 'moment/locale/es';
import 'moment/locale/pt';
import 'moment/locale/tr';
import 'moment/locale/th';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/vi';
import 'moment/locale/zh-cn';
ReactDOM.render(_jsx(Web3ReactProvider, __assign({ getLibrary: getLibrary }, { children: _jsx(Provider, __assign({ store: store }, { children: _jsx(PersistGate, __assign({ loading: null, persistor: persister }, { children: _jsx(ConfigProvider, { children: _jsx(BrowserRouter, __assign({ basename: BASE_PATH }, { children: _jsx(App, {}) })) }) })) })) })), document.getElementById('root'));
serviceWorker.unregister();
reportWebVitals();
