var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext } from 'react';
import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';
var initialState = __assign(__assign({}, defaultConfig), { onChangeLocale: function () { }, onChangeNavType: function () { } });
var ConfigContext = createContext(initialState);
function ConfigProvider(_a) {
    var children = _a.children;
    var _b = useLocalStorage('berry-config', {
        fontFamily: initialState.fontFamily,
        borderRadius: initialState.borderRadius,
        outlinedFilled: initialState.outlinedFilled,
        navType: initialState.navType,
        boxShadow: initialState.boxShadow,
        presetColor: initialState.presetColor,
        locale: initialState.locale,
        rtlLayout: initialState.rtlLayout
    }), config = _b[0], setConfig = _b[1];
    var onChangeLocale = function (locale) {
        setConfig(__assign(__assign({}, config), { locale: locale }));
    };
    var onChangeNavType = function (navType) {
        setConfig(__assign(__assign({}, config), { navType: navType }));
    };
    return (_jsx(ConfigContext.Provider, __assign({ value: __assign(__assign({}, config), { onChangeLocale: onChangeLocale, onChangeNavType: onChangeNavType }) }, { children: children })));
}
export { ConfigProvider, ConfigContext };
