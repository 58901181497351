var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    betslipData: [],
    betAmount: 0,
    betslipOpen: true,
    search: ''
};
var sports = createSlice({
    name: 'sports',
    initialState: initialState,
    reducers: {
        setBetslip: function (state, action) {
            var innerWidth = window.innerWidth;
            if (innerWidth > 767 && action.payload.length > state.betslipData.length) {
                state.betslipOpen = true;
            }
            state.betslipData = __spreadArray([], action.payload, true);
        },
        clearAll: function (state) {
            state.betslipData = [];
        },
        setBetAmount: function (state, action) {
            state.betAmount = action.payload;
        },
        openBetslip: function (state, action) {
            state.betslipOpen = action.payload;
        },
        updateSearch: function (state, action) {
            state.search = action.payload;
        }
    }
});
export default sports.reducer;
export var setBetslip = (_a = sports.actions, _a.setBetslip), setBetAmount = _a.setBetAmount, clearAll = _a.clearAll, openBetslip = _a.openBetslip, updateSearch = _a.updateSearch;
