var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { createTheme, CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import useConfig from 'hooks/useConfig';
import Palette from './palette';
import Typography from './typography';
import customShadows from './shadows';
import componentStyleOverrides from './compStyleOverride';
export default function ThemeCustomization(_a) {
    var children = _a.children;
    var _b = useConfig(), borderRadius = _b.borderRadius, fontFamily = _b.fontFamily, navType = _b.navType, outlinedFilled = _b.outlinedFilled, presetColor = _b.presetColor, rtlLayout = _b.rtlLayout;
    var theme = useMemo(function () { return Palette(navType, presetColor); }, [navType, presetColor]);
    var themeTypography = useMemo(function () { return Typography(theme, borderRadius, fontFamily); }, [theme, borderRadius, fontFamily]);
    var themeCustomShadows = useMemo(function () { return customShadows(navType, theme); }, [navType, theme]);
    var themeOptions = useMemo(function () { return ({
        direction: rtlLayout ? 'rtl' : 'ltr',
        palette: theme.palette,
        mixins: {
            toolbar: {
                // minHeight: '48px',
                padding: '0',
                '@media (min-width: 600px)': {
                    padding: '0',
                    // minHeight: '48px'
                }
            }
        },
        typography: themeTypography,
        customShadows: themeCustomShadows
    }); }, [rtlLayout, theme, themeCustomShadows, themeTypography]);
    var themes = createTheme(themeOptions);
    themes.components = useMemo(function () { return componentStyleOverrides(themes, borderRadius, outlinedFilled); }, [themes, borderRadius, outlinedFilled]);
    return (_jsx(StyledEngineProvider, __assign({ injectFirst: true }, { children: _jsxs(ThemeProvider, __assign({ theme: themes }, { children: [_jsx(CssBaseline, {}), children] })) })));
}
