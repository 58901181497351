import { combineReducers } from 'redux';
import authReducer from './auth';
import menuReducer from './menu';
import sportsReducer from './sports';
import casinoReducer from './casino';
import snackbarReducer from './snackbar';
var reducer = combineReducers({
    auth: authReducer,
    menu: menuReducer,
    sports: sportsReducer,
    casino: casinoReducer,
    snackbar: snackbarReducer
});
export default reducer;
