var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialCurrency = {
    _id: '',
    icon: process.env.REACT_APP_CURRENCY_ICON,
    symbol: process.env.REACT_APP_CURRENCY,
    minBet: 1000,
    maxBet: 100000,
    price: 0.1
};
var initialUser = {
    _id: '',
    email: '',
    username: '',
    iReferral: '',
    avatar: ''
};
var initialState = {
    isInitialized: true,
    isLoggedIn: false,
    code: '',
    betsId: '',
    token: '',
    balance: 0,
    balanceId: '',
    currencyId: '',
    user: initialUser,
    currency: initialCurrency,
    isCasino: false,
    isLogin: false,
    isProfile: false,
    isDeposit: false,
    isWithdraw: false,
    isSlot: false,
    isRegister: false,
    listGameLog: {}
};
var auth = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        Login: function (state, action) {
            var _a = action.payload, balance = _a.balance, user = _a.user, session = _a.session;
            state.user = user;
            state.token = session.accessToken;
            state.isLoggedIn = true;
            state.isInitialized = true;
        },
        UpdateInfo: function (state, action) {
            state.user = action.payload;
        },
        UpdateBalance: function (state, action) {
            state.balance = action.payload;
        },
        UpdateBalances: function (state, action) {
            var balance = action.payload;
            state.balance = balance.balance;
            state.balanceId = balance._id;
            state.currency = balance.currency;
            state.currencyId = balance.currency._id;
            state = __assign({}, state);
        },
        UpdateTotalBalance: function (state, action) {
            state.balance = action.payload;
        },
        SetCode: function (state, action) {
            state.code = action.payload;
        },
        SetBetsId: function (state, action) {
            state.betsId = action.payload;
        },
        Logout: function (state) {
            state.token = '';
            state.balance = 0;
            state.balanceId = '';
            state.currencyId = '';
            state.user = initialUser;
            state.currency = initialCurrency;
            state.isLoggedIn = false;
            state.isInitialized = true;
            state = __assign({}, state);
            if (window.location.pathname.toString().indexOf('blackjack') !== -1 ||
                window.location.pathname.toString().indexOf('roulette') !== -1) {
                window.location.reload();
            }
        },
        changeLogin: function (state, action) {
            state.isLogin = action.payload;
        },
        changeIsCasino: function (state, action) {
            state.isCasino = action.payload;
        },
        changeIsSlot: function (state, action) {
            state.isSlot = action.payload;
        },
        changeIsDeposit: function (state, action) {
            state.isDeposit = action.payload;
        },
        changeIsWithdraw: function (state, action) {
            state.isWithdraw = action.payload;
        },
        changeIsProfile: function (state, action) {
            state.isProfile = action.payload;
        },
        changeIsRegister: function (state, action) {
            state.isRegister = action.payload;
        },
        changeListGameLog: function (state, action) {
            state.listGameLog = action.payload;
        }
    }
});
export default auth.reducer;
export var changeIsRegister = (_a = auth.actions, _a.changeIsRegister), changeListGameLog = _a.changeListGameLog, changeLogin = _a.changeLogin, changeIsWithdraw = _a.changeIsWithdraw, changeIsDeposit = _a.changeIsDeposit, changeIsProfile = _a.changeIsProfile, changeIsCasino = _a.changeIsCasino, changeIsSlot = _a.changeIsSlot, Login = _a.Login, Logout = _a.Logout, UpdateInfo = _a.UpdateInfo, UpdateBalances = _a.UpdateBalances, UpdateBalance = _a.UpdateBalance, SetCode = _a.SetCode, SetBetsId = _a.SetBetsId, UpdateTotalBalance = _a.UpdateTotalBalance;
