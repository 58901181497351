var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState, useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import useConfig from 'hooks/useConfig';
var Locales = function (_a) {
    var children = _a.children;
    var locale = useConfig().locale;
    var _b = useState(), messages = _b[0], setMessages = _b[1];
    useEffect(function () {
        setMessages({
            id: 'en'
        });
    }, [locale]);
    return (_jsx(_Fragment, { children: messages && (_jsx(IntlProvider, __assign({ locale: locale, defaultLocale: "en", messages: messages }, { children: children }))) }));
};
export default Locales;
