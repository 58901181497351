import axios from 'axios';
import { BASE_URL } from 'config';
import { store } from 'store';
import { Logout } from 'store/reducers/auth';
import snackbar from './snackbar';
var axiosServices = axios.create();
axiosServices.interceptors.request.use(function (config) {
    config.baseURL = BASE_URL;
    var state = store.getState();
    var accessToken = state.auth.token;
    if (accessToken) {
        config.headers.authorization = accessToken;
    }
    return config;
}, function (error) { return Promise.reject(error); });
axiosServices.interceptors.response.use(function (response) { return response; }, function (error) {
    var response = error.response;
    if (response && response.status === 400) {
        snackbar(response.data, 'error');
    }
    else if (response && response.status === 401) {
        store.dispatch(Logout());
    }
    else if (response && response.status === 413) {
        snackbar(response.data, 'error');
    }
    else if (response && response.status === 429) {
        snackbar(response.data, 'error');
    }
    else {
        console.log(response);
    }
    return Promise.reject(error);
});
export default axiosServices;
