var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    selectedItem: ['home'],
    drawerOpen: true,
    page: '',
    tabValue: 0,
    activeProviderCasino: false,
    activeCategory: 0,
    nameProvider: {},
    activeGamePlaying: {},
    isProvider: false,
    isLiveCasino: false,
    activeCode: 0,
    activeSearch: false
};
var menu = createSlice({
    name: 'menu',
    initialState: initialState,
    reducers: {
        activeItem: function (state, action) {
            state.selectedItem = action.payload;
        },
        openDrawer: function (state, action) {
            state.drawerOpen = action.payload;
        },
        ChangePage: function (state, action) {
            state.page = action.payload;
        },
        ChangeTab: function (state, action) {
            state.tabValue = action.payload;
        },
        ChangeActiveProviderCasino: function (state, action) {
            state.activeProviderCasino = action.payload;
        },
        ChangeNameProvider: function (state, action) {
            state.nameProvider = action.payload;
        },
        ChangeActiveGame: function (state, action) {
            state.activeGamePlaying = action.payload;
        },
        ChangeActiveCategory: function (state, action) {
            state.activeCategory = action.payload;
        },
        changeActiveProvider: function (state, action) {
            state.isProvider = action.payload;
        },
        changeActiveLiveCasino: function (state, action) {
            state.isLiveCasino = action.payload;
        },
        changeActiveCode: function (state, action) {
            state.activeCode = action.payload;
        },
        changeActiveSearch: function (state, action) {
            state.activeSearch = action.payload;
        }
    }
});
export default menu.reducer;
export var activeItem = (_a = menu.actions, _a.activeItem), openDrawer = _a.openDrawer, ChangePage = _a.ChangePage, ChangeTab = _a.ChangeTab, changeActiveProvider = _a.changeActiveProvider, ChangeActiveProviderCasino = _a.ChangeActiveProviderCasino, ChangeNameProvider = _a.ChangeNameProvider, ChangeActiveGame = _a.ChangeActiveGame, ChangeActiveCategory = _a.ChangeActiveCategory, changeActiveLiveCasino = _a.changeActiveLiveCasino, changeActiveCode = _a.changeActiveCode, changeActiveSearch = _a.changeActiveSearch;
