// export const BASE_URL = 'http://192.168.114.65:1997';
export var BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:2002';
export var BASE_PATH = '/';
export var HOME_PATH = '/';
var config = {
    fontFamily: "'Roboto', sans-serif",
    borderRadius: 8,
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 1px 3px 0px, rgba(0, 0, 0, 0.12) 0px 1px 2px 0px, rgba(255, 255, 255, 0.04) 0px 1px 0px 0px inset',
    outlinedFilled: true,
    navType: 'dark',
    presetColor: 'default',
    locale: 'en',
    rtlLayout: false,
    timer1: 5000,
    timer2: 900000,
    RECAPTCHA_SITE_KEY: '6LfWzvYlAAAAADbSDTZ0VsUMiHlAhUxvI3dnrtiA'
};
export default config;
