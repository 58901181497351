var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
var Loader = function () {
    useEffect(function () {
        var mainDiv = document.getElementsByTagName('main')[0];
        if (mainDiv)
            mainDiv.scrollTop = 0;
    }, []);
    return (_jsxs("div", __assign({ id: "ladingPop", className: "layerPopWrap loadingWrap on" }, { children: [_jsx("div", { className: "bg" }), _jsx("div", __assign({ className: "loadingBox" }, { children: _jsxs("div", __assign({ className: "loading" }, { children: [_jsx("i", {}), _jsx("i", {}), _jsx("i", {}), _jsx("i", {})] })) }))] })));
};
export default Loader;
